// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-design-list-js": () => import("./../../../src/templates/design-list.js" /* webpackChunkName: "component---src-templates-design-list-js" */),
  "component---src-templates-hobby-list-js": () => import("./../../../src/templates/hobby-list.js" /* webpackChunkName: "component---src-templates-hobby-list-js" */),
  "component---src-templates-reading-list-js": () => import("./../../../src/templates/reading-list.js" /* webpackChunkName: "component---src-templates-reading-list-js" */)
}

